<template>
	<v-container fluid>
        <v-container>
            <v-card>
                <v-card-title>{{ $t('catalogue.title') }}</v-card-title>
                <v-card-subtitle>{{ $t('catalogue.subtitle') }}</v-card-subtitle>
                <v-card-text>
                    <form action="/adm/partner_cc_ultimate.php" method="post" target="_blank" @click="brum()">
                        <input name="kk" :value="cat_payload" hidden>
                        <v-btn :disabled="cat.length == 0" block type="submit">{{$t('catalogue.btn_generate')}}</v-btn>
                    </form>
                </v-card-text>
                <v-tabs v-model="tab" centered grow icons-and-text>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab class="smaller">{{ $t('catalogue.tab_content') }}<v-icon></v-icon></v-tab>
                    <v-tab class="smaller">{{ $t('catalogue.tab_settings') }}<v-icon></v-icon></v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <v-list dense v-if="cat.length > 0">
                                <v-list-item v-for="(product, i) in cat" :key="i">
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ i + 1 }}. {{ product.code }}</v-list-item-title>
                                                <v-list-item-subtitle v-text="product.descriptionShort"></v-list-item-subtitle>
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col>
                                                            <v-btn x-small :title="$t('catalogue.btn_title_up')" @click="cat_move(i, i - 1)" :disabled="i == 0"><v-icon>mdi-arrow-up-bold</v-icon></v-btn>
                                                            <v-btn x-small :title="$t('catalogue.btn_title_down')" @click="cat_move(i, i + 1)" :disabled="i == cat.length - 1"><v-icon>mdi-arrow-down-bold</v-icon></v-btn>
                                                            <v-btn x-small :title="$t('catalogue.btn_title_remove')" @click="cat_remove(i)"><v-icon>mdi-delete</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>  
                            </v-list>
                            <v-list v-else>
                                <v-list-item-content>
                                    <v-row>
                                        <v-col class="centered">
                                            <v-list-item-title>{{ $t('catalogue.content_is_empty') }}</v-list-item-title>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list>
                        </v-card>
					</v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-row>
                                <v-col class="centered">
                                    <v-avatar size="300" tile>
                                        <v-img contain src="/pictures/pics/catalogue/cc_help.png"></v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                            
                            <v-card-text>
                                <v-select v-model="cat_form.cc_color_scheme" :label="$t('catalogue.color_scheme')" :items="cc_color_scheme" item-text="color" item-value="value" dense></v-select>
                                <v-text-field v-model="cat_form.cc_project_name" :label="$t('catalogue.project_name')" @change="store_cat_data('cc_project_name')"></v-text-field>
                                <v-text-field v-model="cat_form.cc_short_description" :label="$t('catalogue.short_description')" @change="store_cat_data('cc_short_description')"></v-text-field>
                                <v-text-field v-model="cat_form.cc_web" :label="$t('catalogue.web_contact')" @change="store_cat_data('cc_web')"></v-text-field>
                                <v-text-field v-model="cat_form.cc_email" :label="$t('catalogue.email_contact')" @change="store_cat_data('cc_email')"></v-text-field>
                                <v-text-field v-model="cat_form.cc_tel" :label="$t('catalogue.tel_contact')" @change="store_cat_data('cc_tel')"></v-text-field>
                                
                                <v-row>
                                    <v-col class="centered">
                                        <v-avatar size="150" tile>
                                            <v-img contain :src="'/pictures/pics/catalogue/' + cat_form.pic_logo + '?t=' + rand"></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col>
                                        <v-file-input v-model="cat_form.pic_logo_upload" show-size dense small-chips truncate-length="30" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" :label="$t('catalogue.pic_logo')" @change="upload_cat_img('pic_logo')"></v-file-input>
                                    </v-col>
                                </v-row>
                               <v-row>
                                    <v-col class="centered">
                                        <v-avatar size="150" tile>
                                            <v-img contain :src="malicky('pic_big')"></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col>
                                        <v-file-input v-model="cat_form.pic_big_upload" show-size dense small-chips truncate-length="30" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" :label="$t('catalogue.pic_big')" @change="upload_cat_img('pic_big')"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
        tab: null,
        cat: [],
        rand: Date.now(),
        cat_form: {
            user: -1,
            cc_project_name: '',
            cc_short_description: '',
            cc_web: 'https://',
            cc_email: '',
            cc_tel: '+421',
            cc_list_total: 0,
            cc_list_order: [1],
            cc_list_id: [],
            pic_logo: '',
            pic_big: '',
            pic_logo_upload: '',
            pic_big_upload: '',
            cc_color_scheme: 1
        },
        cc_color_scheme: [
            {color: 'Červená', value: 1},
            {color: 'Zelená', value: 2},
            {color: 'Modrá (bledá)', value: 3},
            {color: 'Modrá (tmavá)', value: 4},
            {color: 'Fialová', value: 5},
            {color: 'Oranžová', value: 6},
   
        ],
        cat_payload: ''
    }),
    methods: {
        cat_move(old_pos, new_pos) {
            this.$store.commit('change_position', { 'old_pos': old_pos, 'new_pos': new_pos})
            this.change_position({ 'old_pos': old_pos, 'new_pos': new_pos })
        },
        cat_remove(pos) {
            this.$store.commit('remove_from_catalogue', pos)
            this.remove_from_catalogue(pos)
        },
        brum() {
            this.cat_form.cc_list_total = this.cat.length
            this.cat_form.cc_list_order = Array.from(Array(this.cat.length).keys())
            for(var item of this.cat){
                this.cat_form.cc_list_id.push(item.id); 
            }
            this.cat_payload = JSON.stringify(this.cat_form)
            console.log(this.cat_payload)
        },
        upload_cat_img(mode) {
            var formData = new FormData()
            formData.append('ID_user', this.$store.getters.get_user_id)
            if(mode == 'pic_logo') {
                formData.append('mode', 'pic_logo')
                formData.append('attachments', this.cat_form.pic_logo_upload)
            }
            else if(mode == 'pic_big') {
                formData.append('mode', 'pic_big')
                formData.append('attachments', this.cat_form.pic_big_upload)
            }
            ApiService.post_attachments('/catalogue/upload', formData).then(() => {
            this.rand = Date.now();
            ApiService.get("/catalogue/personal/" + this.$store.getters.get_user_id).then(response => {
                this.cat_form.pic_logo = response.data.pic_logo
                this.cat_form.pic_big = response.data.pic_big
            }).catch(() => {
            })
            })
        },
        store_cat_data(mode) {
            var formData = new FormData()
            formData.append('ID_user', this.cat_form.user)
            if(mode == 'cc_project_name') {
                formData.append('mode', 'cc_project_name')
                formData.append('val', this.cat_form.cc_project_name)
            }
            else if(mode == 'cc_short_description') {
                formData.append('mode', 'cc_short_description')
                formData.append('val', this.cat_form.cc_short_description)
            }
            else if(mode == 'cc_web') {
                formData.append('mode', 'cc_web')
                formData.append('val', this.cat_form.cc_web)
            }
            else if(mode == 'cc_email') {
                formData.append('mode', 'cc_email')
                formData.append('val', this.cat_form.cc_email)
            }
            else if(mode == 'cc_tel') {
                formData.append('mode', 'cc_tel')
                formData.append('val', this.cat_form.cc_tel)
            }
            ApiService.post('/catalogue/personal/update', formData)
        },
        remove_from_catalogue(position) {
            this.cat.splice(position, 1)
        },
        change_position(payload) {
            var item = this.cat[payload.old_pos]
            this.cat.splice(payload.old_pos, 1)
            this.cat.splice(payload.new_pos, 0, item)
        },
        malicky(mode) {
            if(mode == 'pic_logo') {
                return '/pictures/pics/catalogue/' + this.$store.getters.get_user_id + '_pic_logo.png'
            }
            else if(mode == 'pic_big') {
                return '/pictures/pics/catalogue/' + this.$store.getters.get_user_id + '_pic_big.png'
            }
        }
    },
    mounted() {
        this.cat_form.user = this.$store.getters.get_user_id
        ApiService.post_sectro('/catalogue', {
            ID_user: this.$store.getters.get_user_id,
            cat: this.$store.getters.get_cat_items
        }).then(response => {
            for(var i = 0; i < this.$store.getters.get_cat_items.length; i++) {
                for(var j = 0; j < response.data.products.length; j++) {
                    if(response.data.products[j]['id'] == this.$store.getters.get_cat_items[i]) {
                        this.cat.push(response.data.products[j])
                        response.data.products.splice(j, 1)
                        break
                    }
                }
            }
	}).catch(() => {
	})
	ApiService.get("/catalogue/personal/" + this.$store.getters.get_user_id).then(response => {
		this.cat_form.cc_project_name = response.data.cc_project_name
		this.cat_form.cc_short_description = response.data.cc_short_description
		this.cat_form.cc_web = response.data.cc_web
		this.cat_form.cc_email = response.data.cc_email
		this.cat_form.cc_tel = response.data.cc_tel
		this.cat_form.pic_logo = response.data.pic_logo
		this.cat_form.pic_big = response.data.pic_big
	}).catch(() => {
	})
    }
}
</script>

<style scoped>
.kktin {
    text-align: justify;
}
.smaller {
	font-size: 12px;
}
.centered {
    text-align: center;
}
</style>
